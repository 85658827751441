<template>
  <div class="repair-result-page-wrapper">
    <h2>
      设备报修成功，如需继续维修，请关闭页面，重新扫一扫
    </h2>
  </div>
</template>

<script>
  export default {
    name: 'repair-result',
  }
</script>

<style lang="less">
  .repair-result-page-wrapper {
    width: 355px;
    height: 100vh;
    margin: 0 auto;
    background: #fff;
    h2 {
      margin: 0;
      text-align: center;
      padding-top: 50px;
      .icon-arrow {
        width: 16px;
        height: 16px;
      }
    }
    .btn-back {
      display: block;
      margin: 100px auto;
      width: 100px;
    }
  }
</style>
